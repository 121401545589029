import React from 'react'

const BoxAndImage = ({ title, subtitle, image }) => {
  return (
    <section>
      <div className="bg-black z-10 px-5  py-5 text-ayellow  md:px-32 md:py-20 md:w-[100%] lg:w-[80%]">
        <div className="text-xl  md:text-4xl font-bold">
          <h2>{title}</h2>
        </div>
        <p className="mt-8">{subtitle}</p>
      </div>
      <div className="invisible lg:visible lg:absolute z-20 right-10 top-[90%] ">
        <img
          src={image}
          width={600}
          height={600}
          alt=""
          className="w-100 lg:w-[600px] "
        />
      </div>
    </section>
  )
}

export default BoxAndImage
