import { useState } from 'react'
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios'

const StudentFormv2 = () => {
  // state to keep track of selected track
  const [selectedTrack, setSelectedTrack] = useState('')

  // state to submit form and return success message
  const [formSubmitted, setFormSubmitted] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const handleTrackClick = (track) => {
    setSelectedTrack(track)
    setValue('track', track)
  }

  const sendData = async (data) => {
    try {
      await axios.post(
        'https://func-wave5-academy-brand-prod.azurewebsites.net/api/AddStudentRegistration?code=DuczdadHpHfEUNi7mH7hTlf48ucVDhpJrI_f6X2WS-M6AzFu8fQArQ==',
        data,
      )
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = (data) => {
    setFormSubmitted(true)
    sendData(data)
    setSelectedTrack('')
    reset()
  }
  return (
    <>
      <div className="relative mt-32 lg:mt-80 z-10">
        <div className="bg-black px-10 md:px-14 lg:px-20 py-10 lg:w-[80%] mt-20">
          <div className="flex justify-center">
            <h3 className="text-2xl font-bold font-text text-ayellow">
              Invest in your future and register for the Wave5 Academy.
            </h3>
          </div>
          <div className=" mt-5 mb-5">
            <p className="text-ayellow">
              Fill in the fields in the form below and we will get in touch with
              you as soon as possible. In the meantime, thank you for your
              interest in the Wave5 Academy!
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className=" font-text">
            <div className="mb-6  ">
              <div className="mb-6  ">
                <label
                  htmlFor="firstName"
                  className="block mb-2 text-sm text-ayellow"
                >
                  First Name *
                </label>
                <div className="sm:w-1/2 md:mr-1 mb-6 mr-0">
                  <input
                    type="text"
                    id="firstName"
                    className=" border outline-none text-sm block w-full focus:ring-ayellow focus:border-ayellow   p-2 "
                    placeholder=""
                    required
                    {...register('firstName', {
                      required: true,

                      pattern: /^[A-Za-z\s]+$/i,
                    })}
                  />
                </div>

                <label
                  htmlFor="lastname"
                  className="block mb-2 text-sm text-ayellow"
                >
                  Last Name *
                </label>
                <div className="sm:w-1/2">
                  <input
                    type="text"
                    id="lastname"
                    className=" border-black outline-none focus:ring-ayellow focus:border-ayellow  text-sm block w-full p-2 "
                    placeholder=""
                    required
                    {...register('lastName', {
                      required: true,

                      pattern: /^[A-Za-z\s]+$/i,
                    })}
                  />
                </div>
              </div>
              {errors.firstName && errors.firstName.type === 'pattern' && (
                <p className="text-red-500 text-xs">
                  First name should only contain letters and spaces.
                </p>
              )}
              {errors.lastName && errors.lastName.type === 'pattern' && (
                <p className="text-red-500 text-xs">
                  Last name should only contain letters and spaces.
                </p>
              )}
            </div>

            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 text-sm  text-ayellow"
              >
                Email *
              </label>
              <div className="sm:w-1/2">
                <input
                  type="email"
                  id="email"
                  className="text-awhite text-sm outline-none focus:ring-ayellow focus:border-ayellow  w-full block p-2 placeholder-awhite"
                  placeholder=""
                  required
                  {...register('email', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                />
              </div>
            </div>

            <div className="mb-6">
              <label
                htmlFor="phone"
                className="block mb-2 text-sm text-ayellow"
              >
                Phone number
              </label>
              <div className="sm:w-1/2">
                <PhoneInput
                  containerClass=""
                  inputStyle={{
                    display: 'block',
                    width: '100%',
                    height: '34px',
                    padding: '10px 10px 10px 50px',
                    fontSize: '14px',
                    lineHeight: '1.42857143',

                    color: '#555',
                    backgroundColor: '#fff',
                    backgroundImage: 'none',
                    border: '1px solid #ccc',
                    borderRadius: '0px',
                    webkitBoxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
                    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
                    webkitTransition:
                      'border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s',
                    oTransition:
                      'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
                    transition:
                      'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
                  }}
                  inputClass={{
                    backgroundColor: '#FFFFFF',
                    color: '#FFFFFF',
                    fontSize: '0.875rem',
                    padding: '0.75rem',
                  }}
                  placeholder=""
                  defaultCountry="VN"
                  inputProps={{ id: 'phone', required: true }}
                  onChange={(value) => setValue('phone', value)}
                />
              </div>
            </div>

            <div className="mb-4 text-ayellow md:px-0 pt-5">
              <p className="text-sm text-ayellow mb-2 ">
                Please select the track you are interested in:
              </p>
              <div className="flex items-center marker:text-ayellow">
                <input
                  type="radio"
                  id="SoftwareEngineer"
                  value="SoftwareEngineer"
                  checked={selectedTrack === 'SoftwareEngineer'}
                  onClick={() => handleTrackClick('SoftwareEngineer')}
                  {...register('track', { required: true })}
                  className="checked:bg-ayellow marker:bg-ayellow text-ayellow"
                />
                <label
                  htmlFor="SoftwareEngineer"
                  className="ml-2 text-sm  text-ayellow"
                >
                  Software Engineer
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="DataEngineer"
                  value="DataEngineer"
                  checked={selectedTrack === 'DataEngineer'}
                  onClick={() => handleTrackClick('DataEngineer')}
                  {...register('track', { required: true })}
                  className="checked:bg-ayellow  border-black marker:bg-ayellow text-ayellow"
                />
                <label
                  htmlFor="DataEngineer"
                  className="ml-2 text-sm  text-ayellow"
                >
                  Data Engineer
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="DevOpsEngineer"
                  value="DevOpsEngineer"
                  checked={selectedTrack === 'DevOpsEngineer'}
                  onClick={() => handleTrackClick('DevOpsEngineer')}
                  {...register('track', { required: true })}
                  className="checked:bg-ayellow marker:bg-ayellow text-ayellow"
                />
                <label
                  htmlFor="DevOpsEngineer"
                  className="ml-2 text-sm  text-ayellow"
                >
                  DevOps Engineer
                </label>
              </div>
              {errors.track && errors.track.type === 'required' && (
                <p className="text-red-500 text-xs">Please select a track.</p>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="text-black bg-ayellow hover:bg-opacity-50 hover:text-ablack duration-500 focus:ring-4 focus:outline-none focus:ring-white  text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              >
                Register
              </button>
              {formSubmitted && (
                <div className="text-ayellow text-xs mt-4">
                  Your form has been submitted
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default StudentFormv2
