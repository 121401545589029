import React, { useEffect } from 'react'
import Hero from '../components/Hero'
import AlternativeStudentFeedback from '../components/AlternativeStudentFeedback'
import CertificatesPracticalSkillsStudent from '../components/CertificatesPracticalSkillsStudent'
import BoxAndImage from '../components/BoxAndImage'
import CertificateList from '../components/CertificateList'
import AlternativeStudentFeedbackV2 from '../components/AlternativeStudentFeedbackV2'
import StudentFormv2 from '../components/StudentFormv2.jsx'
import studentStairs from '../images/student-stairs.jpg'
import studentTeacher from '../images/student-teacher.jpg'

const Student = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="pb-20 ">
      <Hero
        title={'Unlock your professional potential.'}
        image={studentStairs}
      />
      <div className="px-16 md:px-32 xl:px-60 md:pt-10 lg:pt-20">
        <div className="md:mt-20 lg:mt-20 lg:w-[70%] xl:mt-40 x2l:mt-40">
          <div className="text-lg lg:text-xl xl:text-2xl font-text">
            <p className="text-xl font-text">
              The Wave5 Academy gives you the skills and tools you need to excel
              in today’s tech industry. By creating a collaborative environment
              where you learn and work closely together with other talented
              students, we equip you with the theoretical, practical, and social
              skills that your new employer needs.
            </p>
            <p className="text-xl font-text">
              This way you can begin your career in tech with a head start and
              be of immediate value to your new employer after you have
              completed the Academy.
            </p>
          </div>
        </div>
        {/* Student feedback component */}

        <div className="flex justify-end mt-5 md:mt-10">
          <AlternativeStudentFeedback
            text={
              'I am grateful for the opportunities provided by the Wave5 Academy. The experience not only expanded my knowledge and skills but also helped me to grow both personally and professionally. I will always cherish the lessons learned and the connections made during this experience.'
            }
            name={'Danh Thanh Nguyen'}
          />
        </div>
        <div className="mt-32 md:mt-20 lg:mt-32 lg:w-[60%]">
          <h3 className="text-2xl md:text-4xl font-text">
            Give yourself the tools to grow and become an expert in your field.
          </h3>
        </div>
        <div className="mt-12 text-lg lg:w-1/2 font-text">
          <p>
            The best skillsets consist of a combination of theoretical,
            practical and social skills that prepare you to succeed in an
            organizational culture and when working on important projects. At
            the Wave5 Academy, you will learn all those skills when studying for
            multiple Microsoft Certificates and while working with others on
            exciting projects.
          </p>
        </div>

        {/* Certificates and Pracitcal skills*/}
        <div className="mt-20 lg:mt-10 font-text">
          <CertificatesPracticalSkillsStudent />
        </div>
        {/* 3 months cerified box with image */}
        <div className="mt-32 ">
          <div className="relative mb-96 ">
            <BoxAndImage
              title={'Certified in 3 months and ready to do work on projects'}
              subtitle={
                'By following one of our three paths the Wave5 Academy will equip you with invaluable skills that make you stand out and ready to take on your new role. You will start working on real projects with the confidence you need to excel right away. And all in three months time.'
              }
              image={studentTeacher}
            />
          </div>
        </div>
        <div className="mt-[-550px] md:mt-[-750px] lg:mt-40">
          <h1 className="text-2xl md:text-4xl font-text lg:w-[60%] pt-20">
            What we offer{' '}
          </h1>
          <h3 className=" font-text lg:w-[60%] pt-5">
            Three tracks that teach you everything you need to become Microsoft
            Certified. Choose one of the carefully designed tracks below and get
            started on your journey of growth.
          </h3>
        </div>
        <div className="mt-10 lg:flex lg:space-x-40 font-text">
          <div className="lg:w-[40%]">
            <div>
              <h3 className="text-xl md:text-2xl font-bold ">
                Software Engineering
              </h3>
              <p className="mt-5">
                At Wave5 Academy's software engineering track, you'll have the
                opportunity to gain industry-recognized certifications in
                various areas of expertise, such as frontend- & backend
                development for web and mobile, cloud native applications. You
                will also learn how to make applications smart by applying AI.
              </p>
              <p className="mt-2">
                By obtaining these certifications, you'll gain knowledge and
                skills in popular Microsoft technologies that are in high demand
                in the software engineering industry, such as Azure services and
                .NET development, which will enhance your career prospects in
                the field of software engineering.
              </p>
              {/* List of microsoft certifications */}
              <div className="pt-5 md:pt-14 mb-20 md:mb-10">
                <CertificateList track={'softwareEngineer'} />
              </div>
            </div>
          </div>

          {/* Student feedback 2nd comp */}
          <AlternativeStudentFeedbackV2
            text={
              'I would definitely recommend registering for the Software Engineering track at the Wave5 Academy. It is the perfect place for recent graduates or university students to grow and become the best software engineers they can be.'
            }
            name={'Phuc Hoang Huu Nguyen'}
          />
        </div>

        <div className="mt-32 lg:flex lg:space-x-40">
          <div className="lg:w-[40%]">
            <div>
              <h3 className="text-xl md:text-2xl font-bold ">
                Data Engineering
              </h3>
              <p className="mt-5">
                At Wave5 Academy's data engineering track, you'll have the
                opportunity to gain industry-recognized certifications in data,
                such as data engineering, data analysis, and data visualization.
              </p>
              <p className="mt-2">
                By obtaining these certifications, you'll gain knowledge and
                skills in popular Microsoft technologies that are in high demand
                in the data engineering industry, such as Azure data services
                and SQL Server, which can enhance your career prospects in the
                field.
              </p>
              {/* List of microsoft certifications */}
              <div className="pt-5 md:pt-14 mb-20 md:mb-10">
                <CertificateList track={'dataEngineer'} />
              </div>
            </div>
          </div>
          {/* v2 alternative student feedback */}
          <AlternativeStudentFeedback
            text={
              'The resources provided by the track were comprehensive and covered all of the topics that appeared on the Microsoft Certifications Exam. As a result, I felt well-prepared and confident when taking the exam.'
            }
            name={'Danh Thanh Nguyen'}
          />
        </div>

        <div className="mt-32 lg:flex lg:space-x-40">
          <div className="lg:w-[40%]">
            <div>
              <h3 className="text-xl md:text-2xl font-bold ">
                DevOps Engineering
              </h3>
              <p className="mt-5">
                At Wave5 Academy's DevOps engineering track, you'll have the
                opportunity to gain industry-recognized certifications in
                various areas of cloud computing, such as implementing cloud
                architecture practices, designing DevOps processes, and managing
                cloud environments.
              </p>
              <p className="mt-2">
                By obtaining a certification, you can demonstrate your
                proficiency and expertise in designing and implementing cloud
                architecture and DevOps practices on Microsoft Azure. Overall, a
                Microsoft certification in DevOps engineering can help you gain
                expertise in this popular field and enhance your career
                prospects in the DevOps engineering industry.
              </p>
              {/* List of microsoft certifications */}
              <div className="pt-5 md:pt-14 mb-20 md:mb-10">
                <CertificateList track={'devOpsEngineer'} />
              </div>
            </div>
          </div>
          {/* Student feedback 2nd comp */}
          <AlternativeStudentFeedbackV2
            text={
              '’The DevOps Engineer track provided me with a solid foundation in DevOps principles and practices, which helped me to prepare and then pass for my Microsoft Certification Exam.'
            }
            name={'Dung Tan Hoang Nguyen'}
          />
        </div>

        {/* Student form */}
        <div className="">
          <StudentFormv2 />
        </div>
      </div>
    </section>
  )
}

export default Student
