import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  const pathname = window.location.pathname
  const checkActive = (path) => {
    if (path === pathname) {
      return 'mr-4 hover:underline duration-500 md:mr-6 underline'
    } else {
      return 'mr-4 hover:underline duration-500 md:mr-6'
    }
  }
  return (
    <div className="bg-ayellow h-full pt-20 font-text font-medium">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8 px-16">
        <hr className="border-slate-900 pb-10 border-0.5" />
        <div className={`${pathname === '/' ? 'hidden ' : 'bg-ayellow '} `}>
          <div className="">
            <div className="mb-10 ">
              <ul className="text-xs md:text-base flex justify-center lg:justify-between lg:px-40  text-ablack">
                <div className="font-normal justify-center md:flex-none">
                  <li>
                    <Link to="/" className={checkActive('/')}>
                      Mission
                    </Link>
                  </li>
                </div>
                <div className="font-normal justify-center md:flex-none">
                  <li className="">
                    <Link to="/about" className={checkActive('/about')}>
                      |
                    </Link>
                  </li>
                </div>
                <div className="font-normal justify-center md:flex-none">
                  <li>
                    <Link to="/student" className={checkActive('/student')}>
                      Student
                    </Link>
                  </li>
                </div>
                <div className="font-normal justify-center md:flex-none">
                  <li className="">
                    <Link href="/about" className={checkActive('/about')}>
                      |
                    </Link>
                  </li>
                </div>
                <div className="font-normal justify-center md:flex-none">
                  <li>
                    <Link to="/company" className={checkActive('/company')}>
                      Organization
                    </Link>
                  </li>
                </div>
                <div className="font-normal justify-center md:flex-none">
                  <li className="">
                    <Link to="/about" className={checkActive('/about')}>
                      |
                    </Link>
                  </li>
                </div>
                <div className="font-normal justify-center md:flex-none">
                  <li className="">
                    <Link to="/about" className={checkActive('/about')}>
                      About
                    </Link>
                  </li>
                </div>
              </ul>
            </div>

            <div className="flex justify-center">
              <div>
                <div className="mb-2 font-text">
                  <div className="text-xs md:font-normal md:text-base  text-ablack">
                    Wave5 Academy is located in Ho-Chi-Minh
                  </div>
                  <div className="flex">
                    <div className=" text-xs md:font-normal md:text-base  text-ablack">
                      Want our academy in your city?
                    </div>
                    <div>
                      <Link to="/listcity" className={checkActive('/listcity')}>
                        <div className="ml-1   text-xs md:text-base text-ablack  cursor-pointer underline hover:text-white duration-500">
                          Let us know
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
