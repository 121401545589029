import axios from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const LocationFormv2 = () => {
  // state to submit form and return success message
  const [formSubmitted, setFormSubmitted] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const sendData = async (data) => {
    try {
      await axios.post(
        'https://func-wave5-academy-brand-prod.azurewebsites.net/api/AddLocationRegistration?code=jY1HLiFxzXl5knj-YcOMY3ogA9wYTCXCTOElKictxj8LAzFuM499Sw==',
        {
          iRepresent: data.representation,
          organizationName: data.company,
          contactSalutation: data.salutation,
          contactFirstName: data.firstName,
          contactLastName: data.lastName,
          contactEmail: data.email,
          contactPhone: data.phone,
          cityOfInterest: data.city,
        },
      )
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = (data) => {
    setFormSubmitted(true)

    sendData(data)

    reset()
  }
  return (
    <div>
      <div className="relative mt-20 lg:mt-40 z-10">
        <div className="bg-black px-10 md:px-14 lg:px-20 py-10 lg:w-[100%] mt-20">
          <div className="flex ">
            <h3 className="text-2xl font-bold font-text text-ayellow">
              Bring the future of tech to your city.
            </h3>
          </div>
          <div className=" mt-5 mb-5">
            <p className="text-ayellow">
              Fill in the form to get more information about bringing the Wave5
              Academy to your community. We will contact you as soon as
              possible!
            </p>
          </div>
          <div className="mt-10 w-100 ">
            <form onSubmit={handleSubmit(onSubmit)} className="  font-text">
              <div className="mb-4 ">
                <div className="">
                  <p className=" text-ayellow mb-2">I represent:</p>
                  <div className="md:flex">
                    <div>
                      <input
                        type="radio"
                        id="myself"
                        name="representation"
                        value="Myself"
                        {...register('representation', {
                          required: true,
                        })}
                        className="checked:bg-ayellow marker:bg-ayellow text-ayellow"
                      />
                      <label
                        htmlFor="myself"
                        className="ml-1 text-sm mr-4 text-slate-200"
                      >
                        Myself
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="company"
                        name="representation"
                        value="Company"
                        {...register('representation', {
                          required: true,
                        })}
                        className="checked:bg-ayellow marker:bg-ayellow text-ayellow"
                      />
                      <label
                        htmlFor="company"
                        className="text-sm ml-1 mr-4 text-slate-200"
                      >
                        Company
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="schoolUniversity"
                        name="representation"
                        value="SchoolUniversity"
                        {...register('representation', {
                          required: true,
                        })}
                        className="checked:bg-ayellow marker:bg-ayellow text-ayellow"
                      />
                      <label
                        htmlFor="schoolUniversity"
                        className="ml-1 text-sm text-slate-200"
                      >
                        School / University
                      </label>
                    </div>
                  </div>
                </div>
                {errors.representation &&
                  errors.representation.type === 'required' && (
                    <p className="text-red-500 text-xs">
                      Please select a representation.
                    </p>
                  )}
              </div>
              <div className="mb-6  ">
                <div className="">
                  <label
                    htmlFor="company"
                    className="block mb-2 text-sm  text-ayellow"
                  >
                    Company *
                  </label>
                  <div className="sm:w-1/2 md:mr-1 mb-6 mr-0">
                    <input
                      type="text"
                      id="company"
                      className=" border outline-none text-sm block w-full  p-2 focus:ring-ayellow focus:border-ayellow  "
                      placeholder=""
                      required
                      {...register('company', {
                        required: true,
                      })}
                    />
                  </div>
                  {errors.company && (
                    <p className="text-red-500 text-xs">
                      Company name is mandatory.
                    </p>
                  )}
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="salutation"
                    className="block mb-2 text-sm  text-ayellow"
                  >
                    Salutation *
                  </label>
                  <select
                    id="salutation"
                    className=" border border-slate-300 text-gray-900 text-sm  focus:ring-ayellow focus:border-ayellow block w-full p-2 sm:w-1/2 md:mr-1 mb-6 mr-0"
                    {...register('salutation', {
                      required: true,
                    })}
                  >
                    <option value="" disabled selected></option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                  </select>
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="firstName"
                    className="block mb-2 text-sm  text-ayellow"
                  >
                    First Name *
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="border border-slate-300 text-gray-900 text-sm  focus:ring-ayellow focus:border-ayellow block w-full p-2 sm:w-1/2 md:mr-1 mb-6 mr-0"
                    placeholder=""
                    required
                    {...register('firstName', {
                      required: true,

                      pattern: /^[A-Za-z\s]+$/i,
                    })}
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="lastname"
                    className="block mb-2 text-sm  text-ayellow"
                  >
                    Last Name *
                  </label>
                  <input
                    type="text"
                    id="lastname"
                    className="b border border-slate-300 text-gray-900 text-sm  focus:ring-ayellow focus:border-ayellow block w-full p-2 sm:w-1/2 md:mr-1 mb-6 mr-0"
                    placeholder=""
                    required
                    {...register('lastName', {
                      required: true,

                      pattern: /^[A-Za-z\s]+$/i,
                    })}
                  />
                </div>
              </div>
              {errors.firstName && errors.firstName.type === 'pattern' && (
                <p className="text-red-500 text-xs">
                  First name should only contain letters and spaces.
                </p>
              )}
              {errors.lastName && errors.lastName.type === 'pattern' && (
                <p className="text-red-500 text-xs">
                  Last name should only contain letters and spaces.
                </p>
              )}
              <div className="mb-6  ">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm  text-ayellow"
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  className=" border border-slate-300 text-gray-900 text-sm  focus:ring-ayellow focus:border-ayellow block w-full p-2 sm:w-1/2 md:mr-1 mb-6 mr-0"
                  placeholder=""
                  {...register('email', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                />
              </div>
              <div className="mb-6  ">
                <label
                  htmlFor="city"
                  className="block mb-2 text-sm  text-ayellow"
                >
                  City of Interest *
                </label>
                <div className="sm:w-1/2 md:mr-1 mb-6 mr-0">
                  <input
                    type="text"
                    id="company"
                    className=" border outline-none text-sm block w-full  p-2 focus:ring-ayellow focus:border-ayellow  "
                    placeholder=""
                    required
                    {...register('city', {
                      required: true,
                    })}
                  />
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-sm  text-ayellow"
                  >
                    Phone number
                  </label>
                  <div className="sm:w-1/2">
                    <PhoneInput
                      containerClass=""
                      inputStyle={{
                        display: 'block',
                        width: '100%',
                        height: '34px',
                        padding: '10px 10px 10px 50px',
                        fontSize: '14px',
                        lineHeight: '1.42857143',
                        color: '#555',
                        backgroundColor: '#fff',
                        backgroundImage: 'none',
                        border: '1px solid #ccc',
                        borderRadius: '0px',
                        WebkitBoxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
                        boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
                        WebkitTransition:
                          'border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s',
                        OTransition:
                          'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
                        transition:
                          'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
                      }}
                      inputClass={{
                        backgroundColor: '#FFFFFF',
                        color: '#FFFFFF',
                        fontSize: '0.875rem',
                        padding: '0.75rem',
                      }}
                      placeholder=""
                      defaultCountry="VN"
                      inputProps={{ id: 'phone', required: true }}
                      onChange={(value) => setValue('phone', value)}
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="text-black bg-ayellow hover:bg-opacity-50 hover:text-ablack duration-500 focus:ring-4 focus:outline-none focus:ring-white font-medium text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                  >
                    Request
                  </button>
                  {formSubmitted && (
                    <div className="text-ayellow text-xs mt-4">
                      Your form has been submitted
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationFormv2
