import React from 'react'
import BrochureForm from '../components/BrochureForm'
import BrochureText from '../components/BrochureText'
import wave5Logo from '../images/Wave5-Academy_Logo_Black.svg'
import { Link } from 'react-router-dom'

// Brochure page

const Brochure = () => {
  return (
    <>
      <div className="px-16  md:px-32 lg:px-60">
        <div className="pt-16 pb-10">
          <Link to="/" className="flex items-center">
            <div className="">
              <img
                src={wave5Logo}
                width={50}
                height={50}
                className="w-40 ml-[-15px]"
                alt="Wave5 Academy Logo"
              />
            </div>
          </Link>
        </div>
      </div>
      <div className="pt-12 flex flex-col lg:flex-row lg:items-start px-16 md:px-32 lg:px-60 mb-20">
        <BrochureText />

        <BrochureForm />
      </div>
    </>
  )
}

export default Brochure
