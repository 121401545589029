import axios from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'

const CompanyFormv2 = () => {
  // state to keep track of selected track
  const [selectedTracks, setSelectedTracks] = useState([])

  // state to submit form and return success message
  const [formSubmitted, setFormSubmitted] = useState(false)

  // state to show validation error for track
  const [trackValidationError, setTrackValidationError] = useState(false)

  // Company selects tracks
  const handleTrackClick = (track) => {
    setTrackValidationError(false)
    if (selectedTracks.includes(track)) {
      setSelectedTracks(selectedTracks.filter((t) => t !== track))
    } else {
      setSelectedTracks([...selectedTracks, track])
    }
  }

  // React hook form
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  // Send data to API
  const sendData = async (data) => {
    if (selectedTracks.length === 0) {
      setTrackValidationError(true)
    } else {
      const tracksString = selectedTracks.join(',')

      try {
        await axios.post(
          'https://func-wave5-academy-brand-prod.azurewebsites.net/api/AddCompanyRegistration?code=pbZD6nu6Ag5rxD30wKWyVU69le-IEltVFtFHMsrrYmOwAzFu2HgVag==',
          {
            companyName: data.companyName,
            contactEmail: data.contactEmail,
            contactFirstName: data.contactFirstName,
            contactLastName: data.contactLastName,
            contactPhone: data.contactPhone,
            contactSalutation: data.contactSalutation,
            hasCandidates: data.haveCandidates ? 1 : 0,
            needsCandidates: data.lookingForCandidates ? 1 : 0,
            tracks: tracksString,
          },
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  // On form submit
  const onSubmit = (data) => {
    sendData(data)
    if (selectedTracks.length === 0) {
      setTrackValidationError(true)
    } else {
      setFormSubmitted(true)
      sendData(data)

      setSelectedTracks([])
      reset()
    }
  }
  return (
    <div>
      <div className="relative mt-20 lg:mt-40 z-10">
        <div className="bg-black px-10 md:px-14 lg:px-20 py-10 lg:w-[80%] mt-20">
          <div className="flex justify-center">
            <h3 className="text-2xl font-bold font-text text-ayellow">
              Register now and reinforce your organization with talent, skills,
              and expertise.
            </h3>
          </div>
          <div className=" mt-5 mb-5">
            <p className="text-ayellow">
              Fill in the fields on the form below and we will contact you as
              soon as possible. In the meantime, thank you for your interest in
              the Wave5 Academy!
            </p>
          </div>
          <div className="mt-10">
            <form onSubmit={handleSubmit(onSubmit)} className=" font-text">
              <div className="mb-6  ">
                <div className="">
                  <label
                    htmlFor="companyName"
                    className="block mb-2 text-sm  text-ayellow"
                  >
                    Company *
                  </label>
                  <div className="sm:w-1/2 md:mr-1 mb-6 mr-0">
                    <input
                      type="text"
                      id="companyName"
                      className=" border outline-none text-sm block w-full  p-2 focus:ring-ayellow focus:border-ayellow  "
                      placeholder=""
                      required
                      {...register('companyName', {
                        required: true,
                      })}
                    />
                  </div>
                  {errors.company && (
                    <p className="text-red-500 text-xs">
                      Company name is mandatory.
                    </p>
                  )}
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="contactSalutation"
                    className="block mb-2 text-sm  text-ayellow"
                  >
                    Salutation *
                  </label>

                  <select
                    id="contactSalutation"
                    className=" border border-slate-300 text-gray-900 text-sm  focus:ring-ayellow focus:border-ayellow block w-full p-2 sm:w-1/2 md:mr-1 mb-6 mr-0"
                    {...register('contactSalutation', {
                      required: true,
                    })}
                  >
                    <option value="" disabled selected></option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                  </select>
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="contactFirstName"
                    className="block mb-2 text-sm  text-ayellow"
                  >
                    First Name *
                  </label>

                  <input
                    type="text"
                    id="contactFirstName"
                    className="border border-slate-300 text-gray-900 text-sm  focus:ring-ayellow focus:border-ayellow block w-full p-2 sm:w-1/2 md:mr-1 mb-6 mr-0"
                    placeholder=""
                    required
                    {...register('contactFirstName', {
                      required: true,

                      pattern: /^[A-Za-z\s]+$/i,
                    })}
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="contactLastName"
                    className="block mb-2 text-sm  text-ayellow"
                  >
                    Last Name *
                  </label>

                  <input
                    type="text"
                    id="contactLastName"
                    className="b border border-slate-300 text-gray-900 text-sm  focus:ring-ayellow focus:border-ayellow block w-full p-2 sm:w-1/2 md:mr-1 mb-6 mr-0"
                    placeholder=""
                    required
                    {...register('contactLastName', {
                      required: true,

                      pattern: /^[A-Za-z\s]+$/i,
                    })}
                  />
                </div>
              </div>
              {errors.firstName && errors.firstName.type === 'pattern' && (
                <p className="text-red-500 text-xs">
                  First name should only contain letters and spaces.
                </p>
              )}
              {errors.lastName && errors.lastName.type === 'pattern' && (
                <p className="text-red-500 text-xs">
                  Last name should only contain letters and spaces.
                </p>
              )}
              <div className="mb-6  ">
                <label
                  htmlFor="contactEmail"
                  className="block mb-2 text-sm  text-ayellow"
                >
                  Email *
                </label>

                <input
                  type="email"
                  id="contactEmail"
                  className=" border border-slate-300 text-gray-900 text-sm  focus:ring-ayellow focus:border-ayellow block w-full p-2 sm:w-1/2 md:mr-1 mb-6 mr-0"
                  placeholder=""
                  {...register('contactEmail', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="contactPhone"
                  className="block mb-2 text-sm  text-ayellow"
                >
                  Phone number
                </label>
                <div className="sm:w-1/2">
                  <PhoneInput
                    containerClass=""
                    inputStyle={{
                      display: 'block',
                      width: '100%',
                      height: '34px',
                      padding: '10px 10px 10px 50px',
                      fontSize: '14px',
                      lineHeight: '1.42857143',
                      color: '#555',
                      backgroundColor: '#fff',
                      backgroundImage: 'none',
                      border: '1px solid #ccc',
                      borderRadius: '0px',
                      webkitBoxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
                      boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
                      webkitTransition:
                        'border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s',
                      oTransition:
                        'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
                      transition:
                        'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
                    }}
                    inputClass={{
                      backgroundColor: '#FFFFFF',
                      color: '#FFFFFF',
                      fontSize: '0.875rem',
                      padding: '0.75rem',
                    }}
                    placeholder=""
                    defaultCountry="VN"
                    inputProps={{ id: 'contactPhone', required: true }}
                    onChange={(value) => setValue('contactPhone', value)}
                  />
                </div>
              </div>

              <div className="mb-4">
                <p className=" text-sm text-ayellow mb-2">
                  I am interested in:
                </p>
                {trackValidationError && (
                  <p className="text-red-500 text-xs">
                    Please select the track you are interested in.
                  </p>
                )}

                <div className="flex items-center">
                  <input
                    type="radio"
                    id="SoftwareEngineer"
                    value="SoftwareEngineer"
                    checked={selectedTracks.includes('SoftwareEngineer')}
                    onClick={() => handleTrackClick('SoftwareEngineer')}
                    className="checked:bg-ayellow marker:bg-ayellow text-ayellow"
                  />

                  <label
                    htmlFor="SoftwareEngineer"
                    className="ml-2 text-sm  text-ayellow"
                  >
                    Software Engineer
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="DataEngineer"
                    value="DataEngineer"
                    checked={selectedTracks.includes('DataEngineer')}
                    onClick={() => handleTrackClick('DataEngineer')}
                    className="checked:bg-ayellow marker:bg-ayellow text-ayellow"
                  />
                  <label
                    htmlFor="DataEngineer"
                    className="ml-2 text-sm  text-ayellow"
                  >
                    Data Engineer
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="DevOpsEngineer"
                    value="DevOpsEngineer"
                    checked={selectedTracks.includes('DevOpsEngineer')}
                    onClick={() => handleTrackClick('DevOpsEngineer')}
                    className="checked:bg-ayellow marker:bg-ayellow text-ayellow"
                  />
                  <label
                    htmlFor="DevOpsEngineer"
                    className="ml-2 text-sm  text-ayellow"
                  >
                    DevOps Engineer
                  </label>
                </div>
              </div>

              <div className="mb-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox text-ayellow"
                    {...register('haveCandidates')}
                  />
                  <span className="ml-2 text-sm text-ayellow">
                    I have candidates
                  </span>
                </label>
                <label className="flex items-center mt-2">
                  <input
                    type="checkbox"
                    className="form-checkbox text-ayellow"
                    {...register('lookingForCandidates')}
                  />
                  <span className="ml-2 text-sm text-ayellow">
                    I am looking for candidates
                  </span>
                </label>
              </div>

              <div>
                <button
                  type="submit"
                  className="text-black bg-ayellow hover:bg-opacity-50 hover:text-ablack duration-500 focus:ring-4 focus:outline-none focus:ring-white  text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                >
                  Send
                </button>
                {formSubmitted && (
                  <div className="text-ayellow text-xs mt-4">
                    Your form has been submitted
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyFormv2
