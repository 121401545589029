import { useEffect } from 'react'
import Hero from '../components/Hero'
import LocationFormv2 from '../components/LocationFormv2'
import listcityFieldCropped from '../images/listcity-fieldCropped.jpeg'

const Listcity = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Hero
        title={'Bring the Wave5 Academy to your city.'}
        image={listcityFieldCropped}
        classname={'lg:h-1/3 xl:h-1/2'}
      />

      <div className="px-16 md:px-32 xl:px-60">
        <div className="md:mt-60  lg:w-[70%] xl:mt-80 x2l:mt-96">
          <p className="text-xl font-text pt-14">
            The Wave5 Academy strives to be a meeting place where local talent
            and exciting tech organizations can come together, helping each
            other grow.
          </p>
          <p className="text-xl font-text pt-5">
            By bringing a Wave5 Academy to your city, you do not just help local
            tech talent to improve themselves, but you will also attract
            business and build a thriving tech community. This helps to
            establish your city as a hub for tech entrepreneurship and
            innovation and gives back to your community.
          </p>
          <p className="text-xl font-text pt-5">
            The Wave5 Academy provides a great place for people to grow and
            learn, equipping them with the tools and skills to help
            organizations innovate at the highest level. This way you will build
            a pipeline of skilled professionals your city can rely on.
          </p>
          <p className="text-xl font-text pt-5">
            By providing tools and skills that help people and organizations
            grow, the Wave5 Academy will help your city become a buzzing hub
            where talent, business, innovation, and tech can come together and
            flourish.
          </p>
          <p className="text-xl font-text pt-5">
            If you are interested in bringing the Wave5 Academy to your city,
            contact us to learn more about the process and how we can work
            together to empower the next generation of tech talent!
          </p>
          {/*   <div className="md:w-2/3 lg:w-1/2">
            <LocationForm />
          </div> */}
          <div className="">
            <LocationFormv2 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Listcity
