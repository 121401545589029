import React from 'react'
import { VscQuote } from 'react-icons/vsc'
import StudentTag from './StudentTag'

const AlternativeStudentFeedbackV2 = ({ text, name }) => {
  return (
    <section className=" mt-5 lg:w-1/2  border-black px-5  py-5">
      <div className="flex pb-5"></div>
      <div className="mt">
        <div>
          <VscQuote size={30} />
        </div>
        <p className="px-5">{text}</p>
        <div className="mb-5 rotate-180">
          <VscQuote size={30} />
        </div>
      </div>
      <div className="ml-5 mt-[-20px]">
        <StudentTag name={name} />
      </div>
    </section>
  )
}

export default AlternativeStudentFeedbackV2
