import React from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { useState } from 'react'

const BrochureForm = () => {
  const [selectedTracks, setSelectedTracks] = useState([])
  // state to submit form and return success message
  const [formSubmitted, setFormSubmitted] = useState(false)

  const [trackValidationError, setTrackValidationError] = useState(false)

  // set user details
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const handleTrackClick = (track) => {
    setTrackValidationError(false)
    if (selectedTracks.includes(track)) {
      setSelectedTracks(selectedTracks.filter((t) => t !== track))
    } else {
      setSelectedTracks([...selectedTracks, track])
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  // Send data to API
  const sendData = async (data) => {
    if (selectedTracks.length === 0) {
      setTrackValidationError(true)
    } else {
      const tracksString = selectedTracks.join(',')

      try {
        await axios.post(
          'https://func-wave5-academy-brand-prod.azurewebsites.net/api/AddCompanyRegistration?code=pbZD6nu6Ag5rxD30wKWyVU69le-IEltVFtFHMsrrYmOwAzFu2HgVag==',
          {
            companyName: data.company,
            contactEmail: data.email,
            contactFirstName: data.firstName,
            contactLastName: data.lastName,
            contactPhone: data.phone,
            contactSalutation: data.contactSalutation,
            hasCandidates: data.haveCandidates ? 1 : 0,
            needsCandidates: data.lookingForCandidates ? 1 : 0,
            tracks: tracksString,
          },
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  const onSubmit = (data) => {
    setFormSubmitted(true)
    sendData(data)
    setFirstName(data.firstName)
    setLastName(data.lastName)
    reset()
  }
  return (
    <>
      <div className="lg:ml-20 pt-10 md:pt-20 lg:pt-0">
        {!formSubmitted && (
          <form onSubmit={handleSubmit(onSubmit)} className="font-text">
            <div className="mb-6  ">
              <div className="mb-6">
                <label
                  htmlFor="contactSalutation"
                  className="block mb-2 text-sm"
                >
                  Salutation *
                </label>

                <select
                  id="contactSalutation"
                  className=" border outline-none text-sm  focus:ring-ablack focus:border-ablack block w-full p-2 sm:w-1/2 lg:w-full xl:w-[180%] md:mr-1 mb-6 mr-0"
                  {...register('contactSalutation', {
                    required: true,
                  })}
                >
                  <option value="" disabled selected></option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Ms.">I prefer not to tell</option>
                </select>
              </div>
              <div className="mb-6  ">
                <label htmlFor="firstName" className="block mb-2 text-sm">
                  First Name *
                </label>
                <div className="sm:w-1/2 xl:w-[180%] lg:w-full md:mr-1 mb-6 mr-0">
                  <input
                    type="text"
                    id="firstName"
                    className=" border outline-none text-sm block w-full focus:ring-ablack focus:border-ablack p-2 "
                    placeholder=""
                    required
                    {...register('firstName', {
                      required: true,

                      pattern: /^[A-Za-z\s]+$/i,
                    })}
                  />
                </div>
                <label htmlFor="lastName" className="block mb-2 text-sm">
                  Last Name *
                </label>
                <div className="sm:w-1/2 lg:w-full xl:w-[180%] md:mr-1 mb-6 mr-0">
                  <input
                    type="text"
                    id="firstName"
                    className=" border outline-none text-sm block w-full focus:ring-ablack focus:border-ablack p-2 "
                    placeholder=""
                    required
                    {...register('lastName', {
                      required: true,

                      pattern: /^[A-Za-z\s]+$/i,
                    })}
                  />
                </div>
              </div>
              {errors.firstName && errors.firstName.type === 'pattern' && (
                <p className="text-red-500 text-xs">
                  First name should only contain letters and spaces.
                </p>
              )}
              {errors.lastName && errors.lastName.type === 'pattern' && (
                <p className="text-red-500 text-xs">
                  Last name should only contain letters and spaces.
                </p>
              )}
            </div>
            <label htmlFor="company" className="block mb-2 text-sm">
              Company *
            </label>
            <div className="sm:w-1/2 xl:w-[180%] lg:w-full md:mr-1 mb-6 mr-0">
              <input
                type="text"
                id="company"
                className=" border outline-none text-sm block w-full focus:ring-ablack focus:border-ablack p-2 "
                placeholder=""
                required
                {...register('company', {
                  required: true,
                  pattern: /^[A-Za-z0-9\s]+$/i,
                })}
              />
            </div>
            <label htmlFor="email" className="block mb-2 text-sm">
              Email *
            </label>
            <div className="sm:w-1/2 xl:w-[180%] lg:w-full md:mr-1 mb-6 mr-0">
              <input
                type="email"
                id="email"
                className=" border outline-none text-sm block w-full focus:ring-ablack focus:border-ablack p-2 "
                placeholder=""
                required
                {...register('email', {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="phone" className="block mb-2 text-sm">
                Phone number *
              </label>
              <div className="sm:w-1/2 lg:w-full xl:w-[180%]">
                <PhoneInput
                  containerClass=""
                  inputStyle={{
                    display: 'block',
                    width: '100%',
                    height: '34px',
                    padding: '10px 10px 10px 50px',
                    fontSize: '14px',
                    lineHeight: '1.42857143',

                    color: '#555',
                    backgroundColor: '#fff',
                    backgroundImage: 'none',
                    border: '1px solid #ccc',
                    borderRadius: '0px',
                    webkitBoxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
                    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
                    webkitTransition:
                      'border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s',
                    oTransition:
                      'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
                    transition:
                      'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
                  }}
                  inputClass={{
                    backgroundColor: '#FFFFFF',
                    color: '#FFFFFF',
                    fontSize: '0.875rem',
                    padding: '0.75rem',
                  }}
                  placeholder=""
                  defaultCountry="VN"
                  inputProps={{ id: 'phone', required: true }}
                  onChange={(value) => setValue('phone', value)}
                />
              </div>
            </div>

            <div className="mb-4">
              <p className=" text-sm mb-2">I am interested in:</p>
              {trackValidationError && (
                <p className="text-red-500 text-xs">
                  Please select the track you are interested in.
                </p>
              )}

              <div className="flex items-center">
                <input
                  type="radio"
                  id="SoftwareEngineer"
                  value="SoftwareEngineer"
                  checked={selectedTracks.includes('SoftwareEngineer')}
                  onClick={() => handleTrackClick('SoftwareEngineer')}
                />

                <label htmlFor="SoftwareEngineer" className="ml-2 text-sm  ">
                  Software Engineer
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="DataEngineer"
                  value="DataEngineer"
                  checked={selectedTracks.includes('DataEngineer')}
                  onClick={() => handleTrackClick('DataEngineer')}
                  className=""
                />
                <label htmlFor="DataEngineer" className="ml-2 text-sm">
                  Data Engineer
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="DevOpsEngineer"
                  value="DevOpsEngineer"
                  checked={selectedTracks.includes('DevOpsEngineer')}
                  onClick={() => handleTrackClick('DevOpsEngineer')}
                  className=""
                />
                <label htmlFor="DevOpsEngineer" className="ml-2 text-sm ">
                  DevOps Engineer
                </label>
              </div>
            </div>

            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  {...register('haveCandidates')}
                />
                <span className="ml-2 text-sm">I have candidates</span>
              </label>
              <label className="flex items-center mt-2">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  {...register('lookingForCandidates')}
                />
                <span className="ml-2 text-sm">
                  I am looking for candidates
                </span>
              </label>
            </div>

            <div>
              <button
                type="submit"
                className="text-ayellow bg-ablack hover:bg-opacity-50 hover:text-ablack duration-500 focus:ring-4 focus:outline-none focus:ring-white  w-full xl:w-[180%] sm:w-auto px-5 py-2.5 mt-3 text-center"
              >
                Contact us
              </button>
              {formSubmitted && (
                <div className="text-black text-xs mt-4">
                  Your form has been submitted.
                </div>
              )}
            </div>
          </form>
        )}
        {formSubmitted && (
          <div className="w-96">
            Dear {firstName} {lastName}, Thank you for your interest in the
            Wave5 Academy! We will contact you soon. Sincerely, Wave5 Academy
            Team
          </div>
        )}
      </div>
    </>
  )
}

export default BrochureForm
