import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Mission from "./pages/Mission";
import Footer from "./components/Footer";
import Student from "./pages/Student";
import Company from "./pages/Company";
import About from "./pages/About";
import Listcity from "./pages/Listcity";
import Brochure from './pages/Brochure'

function App() {
  return (
    <Router>
      <main className="bg-ayellow">
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <Mission />
              </div>
            }
          />
          <Route
            path="/student"
            element={
              <div>
                <Student />
                <Footer />
              </div>
            }
          />
          <Route
            path="/company"
            element={
              <div>
                <Company />
                <Footer />
              </div>
            }
          />
          <Route
            path="/about"
            element={
              <div>
                <About />
                <Footer />
              </div>
            }
          />
          <Route
            path="/listcity"
            element={
              <div>
                <Listcity />
                <Footer />
              </div>
            }
          />
          <Route
            path='/brochure'
            element = {
              <div>
                <Brochure />
                <Footer />
              </div>
            }
            />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
