import { Link } from 'react-router-dom'
import BoxAndImage from '../components/BoxAndImage'
import AlternativeStudentFeedback from '../components/AlternativeStudentFeedback'
import Hero from '../components/Hero'
import aboutRoel from '../images/about-roel.jpg'
import aboutScooters from '../images/about-scooters.jpeg'
import { useEffect } from 'react'

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Hero title={'About us'} image={aboutRoel} />
      <div className="px-16 md:px-32 xl:px-60 md:pt-5 lg:pt-20">
        <div className=" lg:mt-20 lg:w-[70%] xl:mt-40 x2l:mt-40">
          <p className="md:text-lg text-base font-text md:pt-32 pt-4">
            At Wave5, it is our mission to be a great place to grow. For our
            employees, and for our customers. We try to learn every day and help
            our customers accelerate their growth by using the latest technology
            and innovation in the fields of software, data, and AI.
          </p>
          <p className="md:text-lg text-base font-text md:pt-4 pt-4">
            And the best tool for growth is talented, motivated and curious
            people. That is why we started the Wave5 Academy, where we provide
            students with the theoretical and practical skills they need to
            excel in IT and digital roles. By using the Wave5 Academy, it
            becomes much easier for organizations to onboard and retain talent
            that has the skills and knowledge needed to excel in today’s rapidly
            changing digital landscape.
          </p>
        </div>
        {/* Student feedback component */}
        <div className="flex justify-end mt-10">
          <AlternativeStudentFeedback
            text={
              'Studying at Wave5 Academy for three months was an enriching and valuable experience. The curriculum covered various relevant topics and was well-structured. The instructors, who are Microsoft experts, provided me with solid knowledge and helped me develop skills along a specific route which helped me broaden my knowledge and advance in my career.'
            }
            name={'Hoa Xuan Truong'}
          />
        </div>
        <div className=" xl:w-2/3 md:mt-60 mt-20 xl:mt-60 x2l:mt-96">
          <p className="md:text-4xl text-2xl font-text">
            Why choose Wave5 Academy?
          </p>
          <div className="">
            <p className="md:text-lg text-base font-text md:pt-8 pt-4">
              The Wave5 Academy offers comprehensive training programs that
              enable students to gain Microsoft Certifications while also
              working on real-world projects that are relevant to your
              organization's needs. This approach ensures that our graduates are
              fully equipped with the technical and practical skills to
              contribute to your team from day one.
            </p>

            <p className="md:text-lg text-base font-text md:pt-4 pt-2">
              To ensure that graduates hit the ground running when they start
              their career, the Wave5 Academy also focuses on the onboarding
              into the new work environment. We do this by staying in contact
              for the first hundred days after graduating, offering students and
              their new employers support when needed.
            </p>
            <p className="md:text-lg text-base font-text md:pt-4 pt-2">
              At Wave5, we believe that by investing in talent, we can help
              organizations unlock the full potential of digital technologies
              and drive growth. We are committed to supporting the success of
              our students and partners through ongoing collaboration,
              mentorship, and professional development.
            </p>
            <p className="md:text-lg text-base font-text md:pt-4 pt-2">
              We look forward to partnering with you to achieve your digital
              transformation goals!
            </p>
          </div>
          <div className="md:space-x-10 md:flex mt-8 py-10">
            <div className="">
              <Link to="/student">
                <button className="w-[250px]  mb-2 md:mb-0 bg-ablack text-ayellow hover:text-ablack hover:bg-ayellow duration-500 md:px-10 py-2 rounded-3xl">
                  I am a student
                </button>
              </Link>
            </div>
            <div>
              <Link to="/company">
                <button className="w-[250px]  md:mb-0 bg-ablack text-ayellow hover:text-ablack hover:bg-ayellow duration-500 md:px-10 py-2 rounded-3xl">
                  I am an organisation
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="mb-[-400px] md:mb-[-600px] lg:mb-0 mt-32 ">
          <div className="relative mb-96 ">
            <BoxAndImage
              title={
                'We look forward to partnering with you to achieve your digital transformation goals! '
              }
              subtitle={
                'At Wave5, we believe that by investing in talent, we can help organizations unlock the full potential of digital technologies and drive growth. We are committed to supporting the success of our students and partners through ongoing collaboration, mentorship, and professional development.'
              }
              image={aboutScooters}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
