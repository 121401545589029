const CertificatesPracticalSkillsStudent = ({
  textCertificates,
  textSkills,
}) => {
  return (
    <section className="mt-20 ">
      <div className="lg:flex justify-between lg:space-x-10">
        <div className="lg:w-1/2">
          <h3 className="text-2xl md:text-3xl font-bold font-text">
            Microsoft Certificates
          </h3>
          <div className="mt-2">
            <p>{textCertificates}</p>
          </div>
          <div>
            <ol className="mt-5 ml-4 ">
              <li className="list-disc font-text">
                Globally recognized evidence of real-world skills{' '}
              </li>
              <li className="list-disc  font-text">
                It showcases your commitment to keeping pace with changing
                technology.
              </li>
              <li className="list-disc  font-text">
                It positions you for increased skills, efficiency, and earning
                potential in your professional roles.
              </li>
              <li className="list-disc  font-text">
                You will become more productive in your role.
              </li>
              <li className="list-disc  font-text">
                It makes you a more attractive prospect on the job market.
              </li>
            </ol>
          </div>
        </div>
        <div className="mt-10 lg:mt-0 lg:w-1/2 ">
          <h3 className="text-2xl md:text-3xl font-bold font-text">
            Practical skills
          </h3>
          <div className="mt-2">
            <p>{textSkills}</p>
          </div>
          <div>
            <ol className="mt-5 md:ml-4 ">
              <li className="list-disc  font-text">
                You will learn communicational, collaborative, and
                time-management skills to effectively work together.
              </li>
              <li className="list-disc  font-text">
                You will learn how to successfully set up, run, and apply tech
                within a project.
              </li>
              <li className="list-disc  font-text">
                You will learn how to start working agile.
              </li>
              <li className="list-disc  font-text">
                You will learn how to create and read documentation.
              </li>
              <li className="list-disc  font-text">
                You will learn how to test and deploy your work successfully.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CertificatesPracticalSkillsStudent
