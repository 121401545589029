import React from 'react'
import { VscQuote } from 'react-icons/vsc'
import StudentTag from './StudentTag'

const AlternativeStudentFeedback = ({ text, name }) => {
  return (
    <section className=" mt-5 lg:w-1/2 lg:h-1/2  bg-ablack px-5 md:px-10  ">
      <div className="flex pb-5"></div>
      <div className="mt">
        <div>
          <VscQuote size={30} className="text-ayellow" />
        </div>
        <p className="px-5 text-ayellow">{text}</p>
        <div className=" rotate-180">
          <VscQuote size={30} className="text-ayellow" />
        </div>
      </div>
      <div className="ml-5 mt-[-15px] text-ayellow pb-5">
        <StudentTag name={name} />
      </div>
    </section>
  )
}

export default AlternativeStudentFeedback
