import React from 'react'

const BrochureText = () => {
  return (
    <>
      <div className="lg:w-[60%] xl:w-[50%]">
        <h1 className="text-3xl md:text-2xl lg:text-4xl xl:text-5xl font-bold font-text">
          I want to know more about Wave5 Academy
        </h1>
        {/* <p className="pt-6">
          Fill in the form and we will contact you to provide more information
          on what we do and how we can work together.
        </p> */}
        {/* <div className="pt-10">
      <Link to="/student">
        <button className="w-[250px] mb-2 md:mb-0 bg-ablack text-ayellow hover:text-ablack hover:bg-ayellow duration-500 md:px-10 py-2 rounded-3xl">
          Download brochure{' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5 inline pb-1"
          >
            <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
            <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
          </svg>
        </button>
      </Link>
    </div> */}
      </div>
    </>
  )
}

export default BrochureText
