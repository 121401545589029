import React from 'react'
import azureFundamentals from '../images/azure-fundamentals-nobg.svg'
import azureAdministrator from '../images/azure-administrator-nobg.svg'
import azureDeveloper from '../images/azure-developer-nobg.svg'
import azureDataFundamentals from '../images/azure-data-fundamentals-nobg.svg'
import azureDataEngineer from '../images/azure-data-engineer-nobg.svg'
import azureDataAnalyst from '../images/azure-data-analyst-nobg.svg'
import azureDevopsEngineer from '../images/azure-devops-engineer-nobg.svg'

const CertificateList = ({ track }) => {
  const trackCertificates = (track) => {
    if (track === 'softwareEngineer') {
      return [azureFundamentals, azureDataFundamentals, azureDeveloper]
    } else if (track === 'dataEngineer') {
      return [azureFundamentals, azureDataEngineer, azureDataAnalyst]
    } else if (track === 'devOpsEngineer') {
      return [azureFundamentals, azureAdministrator, azureDevopsEngineer]
    }
  }

  return (
    <section className="">
      <h4 className="text-xl font-bold">Your Certificates</h4>
      <div className="mt-5 flex">
        {track && (
          <div className=" ">
            <img
              src={trackCertificates(track)[0]}
              alt="microsoft certificate"
              className="w-28 "
              width={20}
              height={20}
            />
          </div>
        )}

        {track && (
          <div>
            <img
              src={trackCertificates(track)[1]}
              alt="microsoft certificate"
              className="w-28"
              width={20}
              height={20}
            />
          </div>
        )}

        {track && (
          <div>
            <img
              src={trackCertificates(track)[2]}
              alt="microsoft certificate"
              className="w-28"
              width={20}
              height={20}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default CertificateList
