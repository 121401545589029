import React from 'react'
import { Link } from 'react-router-dom'
import wave5Logo from '../images/Wave5-Academy_Logo_Yellow.svg'

const Hero = ({ title, image, classname }) => {
  return (
    <div className="relative pb-10 mb-28 z-10">
      <div className="absolute text-white bg-ablack w-[100%] md:w-[80%] h-[110%] xl:h-[120%] z-[-2]"></div>
      <div className="flex justify-end">
        <div className="invisible md:visible absolute text-white w-[60%] top-40  md:h-[110%] lg:h-[300%] xl:h-[250%] z-[-1]">
          <img
            src={image}
            alt=""
            width={1000}
            height={1000}
            className={classname}
          />
        </div>
      </div>
      <div className="px-16  md:px-32 lg:px-60">
        <div className="pt-16 pb-10">
          <Link to="/" className="flex items-center">
            <div className="">
              <img
                src={wave5Logo}
                width={50}
                height={50}
                className="w-40 ml-[-15px]"
                alt="Wave5 Academy Logo"
              />
            </div>
          </Link>
        </div>
        <div className="">
          <h1 className="text-ayellow w-[50%] lg:w-[60%] xl:w-[50%] text-3xl md:text-4xl lg:text-6xl xl:text-7xl font-bold font-text">
            {title}
          </h1>
        </div>
      </div>
    </div>
  )
}

export default Hero
