import React, { useEffect } from 'react'
import Hero from '../components/Hero'
import AlternativeStudentFeedback from '../components/AlternativeStudentFeedback'
import CertificatesPracticalSkillsStudent from '../components/CertificatesPracticalSkillsStudent'
import BoxAndImage from '../components/BoxAndImage'
import CertificateList from '../components/CertificateList'
import AlternativeStudentFeedbackV2 from '../components/AlternativeStudentFeedbackV2'
import CompanyFormv2 from '../components/CompanyFormv2'
import companyGroup from '../images/company-group.jpg'
import companyLantaarn from '../images/company-lantaarn.jpg'

const Company = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const title = 'Future-proof your business.'
  return (
    <section>
      <Hero title={title} image={companyGroup} />
      <div className="px-16 md:px-32 xl:px-60 md:pt-20">
        <div className="md:mt-20 lg:mt-20 lg:w-[70%] xl:mt-40 x2l:mt-40">
          <div className="text-lg lg:text-xl xl:text-2xl font-text pt-20">
            <p className="text-xl font-text">
              In today's evolving digital landscape, your organization faces
              numerous challenges, including the need to digitize operations,
              the rise of artificial intelligence, and the struggle to attract
              and retain IT talent. The Wave5 Academy helps you build a
              workforce that is ready to tackle these challenges head-on and
              drive your organization forward right away.
            </p>
            <p className="text-xl font-text mt-2">
              Whether you need talent that will strengthen your organization,
              have found talented candidates whose skillset is not yet where you
              need it to be or want to upskill your already existing workforce,
              the Wave5 Academy adds value immediately. And by actively
              supporting you during the onboarding process, you can be sure your
              new talent hits the ground running.
            </p>
            <p className="text-xl font-text mt-2">
              We give our students the skills and tools needed to excel at your
              organization from the start. By creating a collaborative
              environment where they study for their Microsoft Certificates and
              work together on challenging projects, we equip them with the
              theoretical, practical, and social skills that you are looking
              for.
            </p>
          </div>
        </div>
        {/* Student feedback component */}
        <div className="flex justify-end mt-10">
          <AlternativeStudentFeedback
            text={
              'The Wave5 Academy provided me with the opportunity to learn about the latest Microsoft technologies being used around the world. Thanks to the Wave5 Academy, I gained valuable knowledge and skills that I use in my daily job. If you are looking to kick start your career in the tech industry, the Wave5 Academy is the perfect place to begin'
            }
            name={'Dung Tan Hoang Nguyen'}
          />
        </div>
        <div className="mt-40 md:mt-40 lg:w-[60%]">
          <h3 className="text-2xl md:text-4xl font-text">
            Give your talent the tools to grow and become experts in their
            field.
          </h3>
        </div>
        <div className="mt-12 text-lg lg:w-1/2 font-text">
          <p>
            At the Academy, your talent evolves by learning two different skill
            sets. One by studying for and taking the exams for multiple
            Microsoft Certifications, and one by gaining the experience and
            expertise you can only get by working on the job. This combination
            brings significant advantages to your organization.
          </p>
        </div>

        {/* Certificates and Pracitcal skills*/}
        <div className="mt-24 font-text">
          <CertificatesPracticalSkillsStudent
            textCertificates={
              'Microsoft Certified employees are an invaluable asset to your organization. Their globally recognized certification is tangible evidence of their real-world skills, providing your organization with a competitive edge, increased productivity, better team collaboration, and improved business outcomes.'
            }
            textSkills={
              'Practical skills are essential for any organization to thrive, and the Wave5 Academy provides your students with the necessary skills to excel in the workplace.'
            }
          />
        </div>
        <div className="md:flex justify-center bg-black mt-20 p-20">
          <div className="">
            <div className="flex justify-center">
              <h2 className="text-2xl text-ayellow font-bold font-text">
                Seamless onboarding. Ongoing support during transition.
              </h2>
            </div>
            <div className="text-ayellow mt-5">
              <p>
                We understand that the first few months in a new role can be
                challenging, which is why we stay in contact with our graduates
                for the first 100 days after they onboard with you. This ensures
                a smooth transition and helps us provide ongoing support as they
                adjust to their new position.
              </p>
              <p>
                By choosing the Wave5 Academy, you can rest assured that your
                new hires will be equipped with the skills and knowledge needed
                to hit the ground running in their new role, and will have
                access to ongoing support during their transition.
              </p>
            </div>
          </div>
        </div>
        {/* 3 months cerified box with image */}
        <div className="mt-32 ">
          <div className="relative mb-96 ">
            <BoxAndImage
              title={'Certified in 3 months and ready to do work on projects'}
              subtitle={
                'By following one of our three paths the Wave5 Academy will equip your candidates with valuable skills that prepare them to take on their new role at your organization. They will start working on real projects with the confidence they need to excel. And all in three months time.'
              }
              image={companyLantaarn}
            />
          </div>
        </div>
        <div className="mt-[-550px] md:mt-[-350px] lg:mt-40">
          <h1 className="text-2xl md:text-4xl font-text lg:w-[60%] pt-20">
            What we offer{' '}
          </h1>
          <h3 className="text-lg font-text lg:w-[60%] pt-5">
            Three tracks that give your candidates everything they need to
            become Microsoft Certified. By following one of the carefully
            designed tracks below your new tech talent takes big steps in
            becoming experts in their field, bringing immediate value to your
            organization.
            <span className="underline">new tech</span> talent takes big steps
            in becoming experts in their field, bringing immediate value to your
            organization.
          </h3>
        </div>
        <div className="mt-10 lg:flex lg:space-x-40 font-text">
          <div className="lg:w-[40%]">
            <div>
              <h3 className="text-xl md:text-2xl font-bold ">
                Software Engineering{' '}
              </h3>
              <p className="mt-5">
                Wave5 Academy graduates from the software engineering track
                bring a range of skills and expertise in various areas of
                software development, such as front-end- & back-end development
                for web and mobile, cloud native applications. They also learn
                how to make applications smarter by applying AI.
              </p>
              <p className="mt-2">
                By obtaining these certifications, graduates gain knowledge and
                skills in popular Microsoft technologies that are in high demand
                in the software engineering industry, such as Azure services and
                .NET development, which will enhance your career prospects in
                the field of software engineering.
              </p>
              <p className="mt-2">
                In addition, Wave5 Academy's software engineering track provides
                students with hands-on experience working on real-world
                projects, which means they are well-prepared to take on
                challenging software engineering tasks from day one. Graduates
                from this program are equipped with the tools and knowledge they
                need to build efficient, high-quality software solutions, which
                helps your organization to increase productivity and gives you a
                competitive advantage.
              </p>
              {/* List of microsoft certifications */}
              <div className="pt-5 md:pt-14 mb-20 md:mb-10">
                <CertificateList track={'softwareEngineer'} />
              </div>
            </div>
          </div>
          {/* Student feedback 2nd comp */}
          <AlternativeStudentFeedbackV2
            text={
              '"I would definitely recommend registering for the Software Engineering track at the Wave5 Academy. It is the perfect place for recent graduates or university students to grow and become the best software engineers they can be.'
            }
            name={'Phuc Hoang Huu Nguyen'}
          />
        </div>

        <div className="mt-32 lg:flex lg:space-x-40">
          <div className="lg:w-[40%]">
            <div>
              <h3 className="text-xl md:text-2xl font-bold ">
                Data Engineering
              </h3>
              <p className="mt-5">
                Wave5 Academy graduates from the data engineering track will
                bring your organization a strong foundation in data engineering,
                data analysis, and data visualization.
              </p>
              <p className="mt-2">
                Your new employees will have hands-on experience working with
                tools and technologies such as SQL Server, Azure Synapse, and
                Power BI. This gives your organization in-house skills that are
                in high demand in data engineering, data analysis, and data
                science.
              </p>
              <p className="mt-2">
                By obtaining industry-recognized certifications in these areas,
                Wave5 Academy graduates demonstrate their proficiency and
                expertise in data engineering, which will help your organization
                gain insights from their data and make better business
                decisions. Moreover, graduates from this program will bring you
                the skills and knowledge you need to design, implement, and
                maintain data solutions that are efficient, secure, and
                scalable.
              </p>
              <div className="pt-5 md:pt-14 mb-20 md:mb-10">
                <CertificateList track={'dataEngineer'} />
              </div>
            </div>
          </div>
          {/* Student feedback 2nd comp */}
          <AlternativeStudentFeedback
            text={
              'I decided to pursue the Data Engineering track at Wave5 Academy because of my interest in working with technologies related to data, including databases, data warehouses, and big data platforms. This field also presents opportunities for career growth and advancement, with potential roles such as data architect, data scientist, and data manager that I aspire to explore in the future.'
            }
            name={'Danh Thanh Nguyen'}
          />
        </div>
        {/* List of microsoft certifications */}

        <div className="mt-32 lg:flex lg:space-x-40">
          <div className="lg:w-[40%]">
            <div>
              <h3 className="text-xl md:text-2xl font-bold ">
                DevOps Engineering
              </h3>
              <p className="mt-5">
                Wave5 Academy graduates from the DevOps engineering track bring
                your organization skills and expertise in designing,
                implementing, and maintaining, cloud architecture and DevOps
                practices using Microsoft technologies.
              </p>
              <p className="mt-2">
                By obtaining industry-recognized certifications such as Azure
                Administrator Associate and DevOps Engineer Expert, your new
                employees demonstrate a deep understanding of DevOps practices
                and how to apply them in the context of Microsoft technologies.
              </p>
              <p className="mt-2">
                Our graduates will have a high proficiency and expertise in
                designing and implementing cloud architecture and DevOps
                practices on Microsoft Azure which will help your organization
                grow into a cloud ready future.
              </p>
              {/* List of microsoft certifications */}
              <div className="pt-5 md:pt-14 mb-20 md:mb-10">
                <CertificateList track={'devOpsEngineer'} />
              </div>
            </div>
          </div>
          {/* Student feedback 2nd comp */}
          <AlternativeStudentFeedbackV2
            text={
              'The DevOps Cloud Engineer track at Wave5 Academy teaches students valuable skills and knowledge such as problem-solving skills, experience with Git and Kubernetes, understanding of cloud computing concepts, system administration, and software development and deployment concepts like CI/CD. These skills and knowledge are highly relevant in today’s technology industries and can be applied immediately upon completing the program.'
            }
            name={'Hoa Xuan Truong'}
          />
        </div>

        <div className="">
          <CompanyFormv2 />
        </div>
      </div>
    </section>
  )
}

export default Company
