import React from 'react'

const StudentTag = ({ name }) => {
  return (
    <div className="mt-2 md:flex">
      <div className="">
        {/* <Image
          src="/assets/images/alanTuring.png"
          alt="alan turing picture"
          width={32}
          height={40}
          className=" w-14 h-14 rounded-full mr-5"
        /> */}
      </div>
      <div className="flex-row mt-1.5">
        <div className="font-bold font-text">{name}</div>
        <div className="">Wave5 Academy Alumni</div>
      </div>
    </div>
  )
}

export default StudentTag
