import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import wave5Logo from '../images/Wave5-Academy_Logo_Black.svg'
import missionBg from '../images/Mission_bg.png'

const Mission = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="relative w-full min-h-screen flex-col ">
      <img
        src={missionBg}
        width={1000}
        height={1000}
        alt="hero"
        className="absolute top-0 left-0 z-0 object-cover w-[100%] h-[100%]"
      />
      <div className="px-16  md:px-32 lg:px-40 z-10 relative">
        <div className="pt-16">
          <Link to="/" className="flex items-center ">
            <div className="">
              <img
                src={wave5Logo}
                width={50}
                height={50}
                className="w-40 ml-[-15px]"
                alt="Wave5 Academy Logo"
              />
            </div>
          </Link>
        </div>
        <div className="mt-5 md:w-[100%] lg:w-[60%] z-10">
          <p className="md:text-xl font-text mb-5">
            Our mission is to onboard and certify young technology talent,
            equipping them with practical skills and up-to-date knowledge of the
            latest tech to quickly become productive contributors to
            organizations.
          </p>

          <p className="md:text-xl font-text mb-5">
            Through Microsoft certifications, we aim to enable new talent to
            demonstrate their understanding of the latest technology and become
            valuable conversation partners in their teams.
          </p>
          <p className="md:text-xl font-text mb-5">
            By providing a practical and collaborative learning experience, the
            Wave5 Academy gives its students the professional and human skills
            needed to excel in the tech industry of today.
          </p>
        </div>
        <div className="md:space-x-10 md:flex mt-8 py-10">
          <div className="">
            <Link to="/student">
              <button className="w-[250px]  mb-2 md:mb-0 bg-ablack text-ayellow hover:text-ablack hover:bg-ayellow duration-500 md:px-10 py-2 rounded-3xl">
                I am a student
              </button>
            </Link>
          </div>
          <div>
            <Link to="/company">
              <button className="w-[250px]  md:mb-0 bg-ablack text-ayellow hover:text-ablack hover:bg-ayellow duration-500 md:px-10 py-2 rounded-3xl">
                I am an organisation
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission
